.cards {
  padding-top: 2rem;
  padding-bottom: 2rem;

  margin-left: -10px;
  margin-right: -10px;

  display: flex;
  flex-wrap: wrap;
}

.cards-link {
  &:hover {
    text-decoration: none;
  }
}

.cards-scroller {
  position: relative;

  &:hover {
    & .cards-nav-icon {
      &:not([disabled]) {
        opacity: 1;
      }
    }
  }

  & .cards-nav-icon {
    display: none;
    font-size: 2rem;
    opacity: 0;
    color: white;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: rgba(210, 210, 215, 0.8);

    &:hover {
      background-color: rgba(210, 210, 215);
    }

    &[disabled] {
      opacity: 0;
    }

    @media (--media-desktop-size) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & #cards-nav-previous {
    position: absolute;
    transition: opacity 0.25s linear;
    top: calc(220px + 1rem);
    left: 18px;
  }

  & #cards-nav-next {
    position: absolute;
    transition: opacity 0.25s linear;
    top: calc(220px + 1rem);
    right: 18px;
  }
}

.cards--scrollable {
  width: 100vw;

  flex-wrap: nowrap;

  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: var(--container-padding);

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (--media-large-desktop-size) {
    scroll-snap-type: none;
  }

  & .card {
    flex-basis: 80%;
    flex-shrink: 0;

    scroll-snap-align: start;

    &:first-child {
      margin-left: var(--container-padding);
    }

    &:last-child {
      margin-right: 2rem;
    }

    @media (--media-tablet-size) {
      flex-basis: auto;
      width: 380px;
    }

    @media (--media-large-desktop-size) {
      &:first-child {
        margin-left: calc(calc(100vw - var(--max-content-width)) / 2);
      }
    }
  }
}

.card {
  display: flex;
  flex-direction: column;

  overflow: visible;

  color: #1d1d1f;
  background-color: #fff;

  margin: 10px;
  border-radius: 18px;
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);

  &:hover {
    box-shadow: 2px 4px 16px rgb(0 0 0 / 16%);
    transform: scale3d(1.01, 1.01, 1.01);
    text-decoration: none;
  }

  & .header-img {
    display: inline-block;
    background-size: cover;
    background-position: center;

    border-top-left-radius: 18px;
    border-top-right-radius: 18px;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%; /* 16:9 aspect ratio */
    }

  }

  & .footer-img {
    vertical-align: bottom;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }

  & .card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (--media-tablet-size) {
      padding: 30px;
    }
  }

  & .card-title {
    font-size: 22px;
    line-height: 1.14286;
    font-weight: 700;
    letter-spacing: 0.007em;
  }

  & .card-desc {
    font-size: 17px;
    line-height: 1.4;
    font-weight: 300;
    letter-spacing: -0.022em;
    padding-top: 10px;
    margin-top: 0;
  }

  & .card-actions {
    margin-top: auto;

    & .btn {
      margin-top: 1rem;
    }
  }
}

.card.light-content {
  color: white;

  & a {
    color: white;
  }
}
