body {
  margin: 0;
  font-size: 19px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  color: rgb(46, 46, 46);
  background-color: white;
  text-size-adjust: none;

  overflow-x: hidden;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;

  @media (--media-desktop-size) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

main {
  @media (--media-desktop-size) {
    flex-grow: 1;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 36px;
  line-height: 1.3;
  font-weight: 700;
}

h2 {
  font-size: 22px;
  line-height: 1.14286;
  font-weight: 700;
  letter-spacing: 0.007em;
}

h3 {
  font-size: 18px;
}

p {
  margin-block-start: 0;
}

a {
  display: inline-block;

  color: var(--color-frutig-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (--media-desktop-size) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

.subtitle {
  color: #6e6e73;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
}

.container {
  width: calc(100% - calc(var(--container-padding) * 2));
  max-width: var(--max-content-width, 1200px);
  padding: 0 var(--container-padding);
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 0 0 50%;
  max-width: 50%;
}

a.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}
