.blog {

  padding-top: 8rem;

  & h2 {
    margin-block-start: 4rem;
    font-size: 1.7rem;
  }

  & img {
    max-width: 100%;
    height: auto;
  }
  
  & figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    margin-block-start: 2em;
    margin-block-end: 2em;
  }
  
  & figcaption {
      font-size: 1rem;
      max-width: 500px;
      text-align: center;
  }

  & .video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  & .bilder {
    background-color: var(--color-background-gray);
    margin-top: 6rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

    & h2 {
      text-align: center;
    }

    & figure {
      margin-block-end: 6em;
    }

    & figcaption {
      padding-top: 0.75rem;
      font-size: 1.2rem;
    }
  }
}

