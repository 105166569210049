:root {
  --menu-padding-top: 50px;
  --menu-padding-bottom: 30px;
  --menu-sticky-padding-top: 30px;
  --menu-sticky-padding-bottom: 15px;

  & .page-subpage {
    --menu-padding-top: var(--menu-sticky-padding-top);
    --menu-padding-bottom: var(--menu-sticky-padding-bottom);
  }
}

.menu {
  padding-top: var(--menu-padding-top);
  padding-bottom: var(--menu-padding-bottom);
  font-size: 20px;

  width: 100%;
  display: flex;
  justify-content: space-between;

  z-index: 999;
  position: fixed;
  top: 0;

  color: white;

  & .container {
    display: flex;
    justify-content: space-between;

    & .menu-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;

      & .menu-button {
        padding: 0.2em 0.5em;
        margin-right: -0.5em;
        border: none;
        background-color: transparent;
        font-weight: 700;
        font-size: 1.5rem;
        cursor: pointer;

        &:focus ~ .menu-items {
          transform: translate3d(-100vw, 0px, 0px);
        }

        &:active ~ .menu-items {
          transform: translate3d(-100vw, 0px, 0px);
        }
      }
    }

    & ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      padding-bottom: 12px;
      font-weight: 500;

      & li {
        position: relative;
        float: none;
        display: inline-block;

        & a {
          color: white;
          margin-right: 50px;
          width: 100%;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 5px;
          }
        }
      }
    }
  }
}

.page.page-home {
  & .menu--sticky {
    animation: fade-menu 0.25s forwards;
  }
}

@keyframes fade-menu {
  from {
    background-color: rgb(var(--color-background-gray) / 30%);
    transform: scale(1);
    padding-top: var(--menu-padding-top);
    padding-bottom: var(--menu-padding-bottom);
  }
  to {
    background-color: var(--color-background-gray);
    padding-top: var(--menu-sticky-padding-top);
    padding-bottom: var(--menu-sticky-padding-bottom);
  }
}

.menu--sticky {
  color: black;
  background-color: var(--color-background-gray);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

  & .container {
    & .menu-container {
      align-items: center;

      & ul li a {
        color: black;
      }
    }
  }
}

.menu .container .menu-container .menu-items {
  & .menu-close {
    display: none;
  }
}

@media (--media-desktop-size) {
  .menu {
    & .container {
      & .menu-container {
        justify-content: flex-start;

        & ul {
          flex-direction: row;
        }

        & .menu-button {
          display: none;
        }
      }
    }
  }
}

@media (--media-below-desktop-size) {
  .menu--sticky .container .menu-container ul li a {
    color: white;
  }

  .menu .container .menu-container {
    & .menu-items {
      z-index: 998;
      position: fixed;
      box-sizing: border-box;
      font-size: 1.1em;
      top: 0;
      height: 200vh;
      width: 100vw;
      right: -100vw;
      margin: 0;
      color: white;
      background: linear-gradient(90deg, #993333 0%, #BA9833 100%);
      transition: all 500ms ease-in-out 200ms;

      & .menu-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 1.5rem;
        padding: 2rem 2rem;
        border-bottom: 1px solid white;
        background-color: #993333;
      }

      & ul {
        padding: 1rem 2rem;
        font-weight: 300;

        & li {
          width: 100%;
          border-bottom: 1px solid #ffffff47;
          padding: 1.5rem 0;
          margin: 0;
          color: white;
        }
      }
    }
  }
}
