section.landing {
  padding-bottom: 0rem;

  background: rgb(136,72,0);
  background: linear-gradient(90deg, #993333 0%, #BA9833 100%);

}

.landing {
  & .container {
    display: flex;
    flex-wrap: wrap;
  }

  padding-top: 6rem;
  color: white;

  & h1 {
    font-size: 50px;
    color: #fff;
    margin: 0;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  & p {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0.2px;
  }

  & .content {
    padding-top: 3rem;
    padding-bottom: 3rem;

    & .cta {
      margin-top: 50px;

      & a {
        border-radius: 8px;
        box-shadow: 0 10px 15px -3px rgb(16 42 67 / 10%),
          0 4px 6px -2px rgb(16 42 67 / 5%);
        background-color: var(--color-frutig-red);
        color: #fff;
        text-align: center;
        padding: 15px 30px;
        font-size: 20px;
        text-decoration: none;

        &:hover {
          box-shadow: 0 10px 15px 0px rgb(16 42 67 / 15%),
            0 4px 6px 0px rgb(16 42 67 / 15%),
            inset 0 0 0 10em rgba(255, 255, 255, 0.1);
          cursor: pointer;
        }
      }
    }
  }

  & .chat {
    display: none;
    align-items: flex-end;

    & .bot {
      width: 90px;
    }

    & .user {
      width: 140px;
    }

    & .chat-phone {
      border: 10px solid #000;
      border-radius: 54px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 360px;
      max-width: 95vw;
      height: 680px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      margin-left: 20px;

      background-color: #c03ca9;

      & .phone-header {
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        box-shadow: 0 4px 30px rgb(0 0 0 / 10%);

        display: flex;
        align-items: center;
        flex-direction: column;

        width: 100%;
        position: absolute;
        z-index: 2;
        top: -3px;
        text-align: center;

        & .notch {
          width: 60%;
        }

        & span {
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 1rem;
        }
      }

      & iframe {
        height: 100%;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        position: absolute;
      }
    }
  }
}

@media (--media-large-desktop-size) {
  .landing {
    padding-top: 6rem;

    & p {
      font-size: 24px;
      line-height: 42px;
    }

    & .chat {
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
