footer {
  background-color: var(--color-background-black);
  color: white;

  & .waves {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 600px;
    display: flex;
    justify-content: center;
    min-height: 250px;
  }

  & section {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  & .footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;

    padding-bottom: 2rem;

    font-size: 14px;
    font-weight: 300;

    & a {
      color: white;
    }

    & h2 {
      font-size: 18px;
    }

    & p {
      font-size: 14px;
      font-weight: 300;
    }

    & .about {
      grid-column: 1 / 5;
      grid-row: 1;

      @media (--media-desktop-size) {
        grid-column: 1 / 3;
      }
    }

    & .social {
      margin-top: 2rem;
      font-size: 1.5rem;
    }

    & .contact {
      grid-column: 1 / 5;
      grid-row: 2;

      @media (--media-tablet-size) {
        grid-column: 1 / 3;
        grid-row: 2;
      }

      @media (--media-desktop-size) {
        grid-column: 3 / 4;
        grid-row: 1;
      }
    }

    & .quick-links {
      grid-column: 1 / 5;
      grid-row: 3;

      @media (--media-tablet-size) {
        grid-column: 3 / 5;
        grid-row: 2;
      }

      @media (--media-desktop-size) {
        grid-column: 4;
        grid-row: 1;
      }

      & ul {
        padding-left: 0px;
        list-style-type: none;
      }
    }
  }

  & .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    font-size: 12px;
    font-weight: 300
  }
}
