section.partials-referenzprojekte {
  background-color: var(--color-background-gray);
}

@media (--media-desktop-size) {
  section.partials-referenzprojekte {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
}
