.image--expandable {
  cursor: pointer;
  transition: 0.3s;

  @media only screen and (min-width: 700px) {
    &:hover {
      opacity: 0.8;
    }
  }
}

#image-modal {
  display: none;
  position: fixed;
  z-index: 99999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);

  @media only screen and (min-width: 700px) {
    cursor: pointer;
  }

  & #image-modal-content {
    margin: auto;
    display: block;
    width: auto;
    max-width: 80%;
    max-height: 80%;
  }

  & #image-modal-caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }

  & #image-modal-caption {
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  & #image-modal-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }
    &:focus {
      color: #bbb;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
