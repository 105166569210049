:root {
  --max-content-width: 900px;
  --container-padding: 25px;
  --color-frutig-blue: #2f55eb;
  --color-frutig-red: #FF0000;
  --color-background-gray: #f5f5f7;
  --color-background-black: #001220;
}

@custom-media --media-phone-size (max-width: 767px);
@custom-media --media-tablet-size (min-width: 768px);
@custom-media --media-below-desktop-size (max-width: 1023px);
@custom-media --media-desktop-size (min-width: 1024px);
/* larger then max-content-width */
@custom-media --media-large-desktop-size (min-width: 1200px);
