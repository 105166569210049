button {
  border: 0;
  cursor: pointer;
  font-family: inherit;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5715;
  position: relative;
  font-weight: 300;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  box-shadow: 0 2px #00000004;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;

  margin: 0em;
  padding: 0.3rem;

  font-size: 14px;
  border-radius: 4px;
  color: var(--color-frutig-blue);
  border-color: var(--color-frutig-blue);
  background-color: #fff;
  text-decoration: none;

  &:hover {
    background-color: var(--color-frutig-blue);
    color: white;
    border-color: var(--color-frutig-blue);
    text-decoration: none;
  }
}

.btn-block {
  width: 100%;
}

.btn.btn-primary {
  color: #fff;
  border-color: var(--color-frutig-blue);
  background-color: var(--color-frutig-blue);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px #0000000b;

  &:hover {
    background-color: color-mod(var(--color-frutig-blue) whiteness(30%));
    border-color: color-mod(var(--color-frutig-blue) whiteness(30%));
  }
}

.btn.btn-lg {
  font-size: 16px;
  border-radius: 8px;
}

a.button {
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

a.button.button-action {
  &::before {
    content: "→";
    display: inline-block;
    text-decoration: none;
    margin-right: 0.3em;
  }
}

@media (--media-desktop-size) {
  a.button {
    display: inline;
    &:hover {
      text-decoration: underline;
    }
  }
}
